body {
    background-color: var(--main-color);
  }
.anime{
    background-color: var(--main-color);
    overflow: hidden;

    .settings{
      font-size: 14px;
      display: flex;
      position: relative;
      float: right;
      margin: 13px;
      color: wheat;
      cursor: pointer;
      &:hover {
        //font-size: 51px!important;
        color: red;
      }

      .options {
        display: none;
        background-color: var(--main-color);
        border-radius: 5px;
        right: 30px;
      }
      span {
        padding: 10px;
        cursor: pointer;
      }

      &:click {
        .options {
          display: flex;
          flex-direction: column;
          position: absolute;
        }
      }

    }

    .temporada {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0 5px 0px;
        width: 100vw;
        color: rgb(191, 210, 210);
        margin-top: 15px;
    }
    
    .audio {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0 5px 0px;
        width: 100vw;
        color: rgb(191, 210, 210);
    }

    .form-select {
        display: block;
        width: 30%;
        padding: .375rem 2.25rem .375rem .75rem;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: right .75rem center;
        background-size: 16px 12px;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    
    .form-select-lg {
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: 1rem;
        font-size: 1.25rem;
    }

    .infoNo{
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 50px 0 5px 0px;
        font-size: 30px;

        .skeleton{
          margin-left: 50px;
          background-color: grey;
        }
    }

    .iconCap{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
    
        .spacin{
          margin-left: 200px;
        }
        .icon {
          font-size: 50px!important;
          color: white;
          padding: 5px;
          border-radius: 50%;
          margin-right: 10px;
          //padding-left: 200px;
          cursor: pointer;
          &:hover {
            //font-size: 51px!important;
            color: red;
          }
        }
    
        .iconR{
          font-size: 50px!important;
          color: red;
          padding: 5px;
          border-radius: 50%;
          margin-right: 10px;
          //padding-left: 200px;
          cursor: pointer;
    
          &:hover {
            //font-size: 51px!important;
            color: white;
          }
        }
    
      }
      .count{
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        right: 11px;
    
    
        .spacin{
          margin-left: 255px;
        }
      }
}


