
.watch {
  position: absolute;
  width: 100%;
  height: 100%;

  .back {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    cursor: pointer;
    z-index: 2;
  }

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .iframev{
    border: 0;
    width: 100%;
    height: 85%;
  }


  .info{
    display: grid;
    align-items: center;
    justify-content: center;

    .anime{
      color: red;
      font-size: 15px;
      margin-top: 15px;
      justify-content: center;
      display: flex;
    }
    .name{
      color: white;
      font-size: 25px;
      margin-top: 15px;
      justify-content: center;
      display: flex;
    }

  }

  .iconCap{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    .spacin{
      margin-left: 200px;
    }
    .icon {
      font-size: 50px!important;
      color: white;
      padding: 5px;
      border-radius: 50%;
      margin-right: 10px;
      //padding-left: 200px;
      cursor: pointer;
      &:hover {
        //font-size: 51px!important;
        color: red;
      }
    }

    .iconR{
      font-size: 50px!important;
      color: red;
      padding: 5px;
      border-radius: 50%;
      margin-right: 10px;
      //padding-left: 200px;
      cursor: pointer;

      &:hover {
        //font-size: 51px!important;
        color: white;
      }
    }

  }
  .count{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;


    .spacin{
      margin-left: 260px;
    }
  }
}
